const colors = {
  apple: "#2FC76F",
  banana: "#FCD381",
  ocean: "#5D85EC",
  background: "#ffffff",
  black: "#000000",
  charcoal: "#4F4F4F",
  night: "#35383F",
  navy: "#4E5C75",
  olympicSkaterSpandex: "#8190A6",
  eggshell: "#A2B8AD",
  oldCouch: "#809F90",
  ivy: "#27AE60",
  keyLimePie: "#CAEAD6",
  paper: "#F6F7FA",
  primary: "#27AE60",
  sky: "#D4E3F0",
  text: "#4F4F4F",
  tomato: "#EE6A20",
  white: "#ffffff",
  rainCloud: "rgba(205,205,205,0.7)",
  blue: "#278BD6",
  darkBlue: "#1c78c8",
  grey: "#898F9C",
};

export default colors;
