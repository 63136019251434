const depth = {
  /* Hidden behind everything else */
  hidden: -1,
  /* Default for all elements */
  normal: 0,
  /* Above normal elements */
  elevated: 5,
  /* Above elevated elements (exceptional high-value cases, e.g. tooltips) */
  high: 10,
  /* Above all elements */
  cover: 999,
  /* Above backdrop (e.g. backdrop content, modal) */
  overlay: 1000,
  /* Above overlay (e.g. toasts) */
  top: 1001,
};

export default depth;
