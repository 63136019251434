import React from "react";
import { styled } from "linaria/react";
import { CSSProperties, FC, memo } from "react";
import { colors, FontScale, SpaceScale } from "theme";
import { UnitlessScale } from "src/theme/scale";
import useMemoizedChildren from "hooks/useMemoizedChildren";

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;
type StyleProps = {
  color?: keyof typeof colors;
  weight?: 400 | 500 | 700 | 900;
};
type Props = {
  level?: HeadingLevel;
  className?: string;
  style?: CSSProperties;
  children: React.ReactNode;
} & StyleProps;
const Heading: FC<Props> = ({
  level = 2,
  children,
  className,
  color = "charcoal",
  weight: fontWeight = 600,
  style,
  ...rest
}) => {
  const memoizedChildren = useMemoizedChildren(children);
  const Component = `h${level}`;
  const fontSize = FontScale(7.125 - level);
  const lineHeight =
    level === 1
      ? UnitlessScale(4.125)
      : Math.max(1, UnitlessScale(6.75 - level));

  return (
    // @ts-ignore
    <Component
      className={className}
      style={{
        color: colors[color],
        fontSize,
        fontWeight,
        lineHeight,
        ...style,
      }}
      {...rest}
    >
      {memoizedChildren}
    </Component>
  );
};
const StyledHeading = styled((props: Props) => (
  <Heading {...props} />
))<StyleProps>`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
`;
export default memo(StyledHeading);
