const transition = {
  spring: {
    mass: 1,
    tension: 105,
    friction: 15,
  },
  springFast: {
    mass: 1,
    tension: 120,
    friction: 14,
  },
};

export default transition;
