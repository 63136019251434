import { FontScale } from "./scale";

export const fontSizes = FontScale().map((step: any) => `${step}rem`);
export const fonts = {
  body:
    'Apercu, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  heading:
    'Apercu, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  monospace:
    'ApercuMono, ui-monospace, "SF Pro", "Lucida Console", Monaco, monospace',
};
