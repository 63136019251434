import React, { FC } from "react";
import { styled } from "linaria/react";
import { SpaceScale, colors } from "theme";
import { MAX_WIDTH } from "src/theme/scale";
import useMemoizedChildren from "hooks/useMemoizedChildren";

type Props = {
  style?: any;
  className?: string;
  background?: keyof typeof colors;
  color?: keyof typeof colors;
};
const Section: FC<Props> = ({
  style,
  className,
  children,
  background,
  color,
  ...rest
}) => {
  const memoizedChildren = useMemoizedChildren(children);
  return (
    <section
      className={className}
      style={{
        backgroundColor: colors[background],
        color: colors[color],
        ...style,
      }}
    >
      <Inner {...rest}>{memoizedChildren}</Inner>
    </section>
  );
};

const Inner = styled.div`
  max-width: ${MAX_WIDTH};
  margin: 0 auto;
  padding: ${SpaceScale(5)};
`;

export default Section;
