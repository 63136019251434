import { Ratio, Scale } from "natural-scale";

export const MAX_WIDTH = "1400px";
export const DEFAULT_UNIT = "rem";
export const FontScale: any = Scale({
  interval: Ratio.GOLDEN_RATIO,
  unit: DEFAULT_UNIT,
});
export const UnitlessScale: any = Scale({
  interval: Ratio.GOLDEN_RATIO,
  unit: null,
});

export const SpaceScale: any = Scale({
  interval: Ratio.GOLDEN_RATIO,
  unit: DEFAULT_UNIT,
});
